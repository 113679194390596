<template>
	<div class="goodsList">
		<p class="flex flexC flexB mb20 fs20 bold6">订单列表</p>
    <el-form ref="form" class="form flex flexW flexC mb40">
      <el-form-item label="订单号：" class="aliL pr40">
        <el-input v-model="orderNo" placeholder="请输入订单号" ></el-input>
      </el-form-item>
      <el-form-item label="时间：" class="aliL pr40">
        <el-date-picker  format="YYYY-MM-DD" value-format="YYYY-MM-DD" @change="selectDate" v-model="dateTime" type="date" placeholder="选择日期">
        </el-date-picker>
      </el-form-item>

      <el-form-item class="aliL pr40">
        <el-button class="ml40" type="primary" size="mini" @click="search">搜 索</el-button>
      </el-form-item>
    </el-form>
<!--		<div class="aliL mb40">-->
<!--			<el-button class="mr40" type="primary" size="mini">导出订单</el-button>-->
<!--			<el-button type="primary" plain size="mini">导 入</el-button>-->
<!--		</div>-->
		<el-tabs v-model="activeIndex" @tab-click="handleTabClick">
			<el-tab-pane v-for="(item,index) in tabs" :key="index" :label="item" :name="index"></el-tab-pane>
		</el-tabs>

		<el-table  :data="list" class="mt15 bort borl" style="width: 100%" v-loading="isLoading" header-cell-class-name="tabbg">
			<el-table-column width="50" prop="id" label="ID" />
			<el-table-column prop="order_no" width="250" label="订单号" />
			<el-table-column prop="total_price" width="100"  label="订单金额" />
			<el-table-column prop="create_time" label="订单时间" />
			<el-table-column prop="express_price" label="邮费" />
			<el-table-column prop="express_no" label="快递单号" />
			<el-table-column prop="status" label="订单状态" />
			<el-table-column label="操作" fixed="right">
				<template #default="scope" >
					<el-button type="text" size="small" @click="handleClick(scope.row.id)">详情</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination @next-click="next" @prev-click="prev" @current-change="sizeChange" class="mt30 aliR" background layout="prev, pager, next" :page-size="pageSize" :total="total"> </el-pagination>

	</div>

		<el-dialog v-model="dialogisible" title="订单详情">
			<p class="pb16 aliL">订单编号:{{orderDetail.order_no}}（订单时间{{orderDetail.pay_time_text}}）</p>
			<div class="r10 bor box aliL">
				<p class="bgf5 py12 px20 c33">商品详情</p>
				<el-row class="py12 px20" v-for="good in orderDetail.goods">
					<el-col :span="16">{{good.goods_name}} -- {{good.spec_value}}--{{good.factory_name}}</el-col>
					<el-col :span="4">{{good.goods_price}}元</el-col>
					<el-col :span="4">数量：{{good.total_num}}</el-col>
				</el-row>
			</div>
			<el-row class="mt20 aliL">
				<el-col :span="8">
					<p class="mb10"><el-tag type="danger" effect="dark">支付状态</el-tag>：<span>已支付</span></p>
                    <p class="mb10"><el-tag type="danger" effect="dark">总金额（含邮费）</el-tag>：<span>{{orderDetail.total_price}}</span></p>

                </el-col>
				<el-col :span="8">
					<p class="mb10"><el-tag type="success" effect="dark">发货时间</el-tag>：<span>{{orderDetail.freight_time_text}}</span></p>
					<p class="mb10"><el-tag type="" effect="dark">快递单号</el-tag>：<span>{{orderDetail.express_no}}</span></p>
				</el-col>
				<el-col :span="8">
					<p class="mb10"><el-tag type="success" effect="dark">邮费</el-tag>：<span>{{orderDetail.express_price}}</span></p>
				</el-col>
			</el-row>
			<p class="mt20 pb8 aliL borb bold6 fs16 c33">收货人信息</p>
			<el-row class="py18 aliL borb">
				<el-col :span="12">
					<p class="mb10">省/市/区：{{orderDetail.address.province.name}}/{{orderDetail.address.city.name}}/{{orderDetail.address.area.name}}</p>
					<p>联系人：{{orderDetail.address.name}}</p>
				</el-col>
				<el-col :span="12">
					<p class="mb10">具体地址：{{orderDetail.address.detail}}</p>
					<p>联系电话：{{orderDetail.address.phone}}</p>
				</el-col>
			</el-row>

			<p class="mb20 mt30 pb8 aliL borb bold6 fs16 c33">发货信息</p>
			<el-form :model="form" label-width="100px" >

				<el-form-item label="快递单号:" class="w70">
					<el-input v-model="expressNo"></el-input>
				</el-form-item>
			</el-form>
			<template #footer v-if="orderDetail.freight_status != 20">
				<p class="aliC pb40">
					<el-button type="primary" @click="doSend">确认发货</el-button>
				</p>
			</template>
		</el-dialog>

</template>

<script>

    import api from '@/config/api'
	import { ElMessage } from 'element-plus'

	export default {
		data() {


			return {
				expressNo:'',
				dialogisible: false,
				activeIndex: 0,
				pageSize:10,
                page:1,
				total:0,
				tabs: ['全部','待发货','待收货','已完成'],
				form: {
					name: '',
					type: ''
				},
                selectStatus:'',
				list:[],
        isLoading:true,
        status:'',
        orderNo:'',
        dateTime:'',
        orderDetail:{},
        id:''
			};
		},
		mounted() {
			this.getOrdeList()

		},
		methods: {
		    next(page){
                console.info("==next=="+page);
                this.page = page;
                this.list = [];
                this.isLoading = true;
                this.$forceUpdate();
                this.getOrdeList();

            },
            prev(page){
                console.info("==prev=="+page);
                this.page = page;
                this.list = [];
                this.isLoading = true;
                this.$forceUpdate();
                this.getOrdeList();
            },
            sizeChange(page){
                console.info("==sizeChange=="+page);
                this.page = page;
                this.isLoading = true;
                this.list = [];
                this.$forceUpdate();
                this.getOrdeList();
            },

            statusChange(value){
		        if(value == '全部'){
		            this.status = "";
                }else if(value =='待发货'){
		            this.status = "payed"
                }else if(value == '待收货'){
                    this.status = "transit"
                }else if(value == "已完成"){
                    this.status = "done"
                }
                this.selectStatus = value;
                console.info("===selectStatus==="+this.selectStatus);
                this.$forceUpdate();

            },

			handleTabClick() {
				console.log('tabclick')
                if(this.activeIndex == 0){
                    this.status = "";
                }else if(this.activeIndex == 1){
                    this.status = "payed";
                }else if(this.activeIndex == 2){
                    this.status = "transit";
                }else if(this.activeIndex == 3){
                    this.status = "done";
                }
                this.page = 1;
                this.isLoading = true;
                this.list = [];
                this.$forceUpdate();
                this.getOrdeList();
			},
			handleClick(id) {
                this.$forceUpdate();
                this.id = id;
                this.getOrderDetail(id);

			},
            doSend(){
                this.dialogVisible = false;
                this.$forceUpdate();

                this.send();

            },
			selectDate(e){

		    	console.info(this.dateTime);
			},

			search(){
				this.page = 1;
				this.isLoading = true;
				this.list = [];
				this.$forceUpdate();
				console.info(this.dateTime);
				this.getOrdeList();
			},

            async send(){

				let data = {
					id: this.id,
					expressNo:this.expressNo
				}
				const res = await api.express(data);
				if (res.code == 1) {
					this.dialogisible = false;
					ElMessage({
						showClose: true,
						message: '处理成功',
						type: 'success',
					})
				}else{

				}
            },

            async getOrderDetail(id){
                let data = {
                    id: id
                }
                const res = await api.orderDetail(data);
                if (res.code == 1) {
                    this.orderDetail = res.data;
                    this.dialogisible = true;
                    this.$forceUpdate();
					this.getOrdeList();
                }

            },

			async getOrdeList() {

                let data = {
					page: this.page,
					status: this.status,
					orderNo:this.orderNo,
					dateTime:this.dateTime
				}
				const res = await api.orderList(data);
				if (res.code == 1) {
					this.total = res.data.total;
					this.list = res.data.list;
                    this.isLoading = false;
                    this.$forceUpdate();

                    console.info("===total===" + this.total);
					console.info("===total===" + JSON.stringify(this.list));

				}
			},
		}

	}
</script>

<style lang="scss" scoped>
.goodsList {
	.form {margin-left: 0 !important;margin-right: 0 !important;
    .label {min-width: 60px;display: inline-block;}
  }
	.el-table--fit {border-right: 1px solid #eee;}

}
.box {overflow: hidden;}
.w70 {width: 70%;}
</style>
